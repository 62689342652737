import { AVAILABLE_FEATURE as COMMON_AVAILABLE_FEATURE } from '@margobank/components/feature-toggles';

/**
 * The list of all current feature flags.
 *
 * Example:
 *
 * ```
 *   CHANGE_PAIRED_DEVICE: 'change-paired-device',
 * ```
 *
 * 📜 Please keep [the matching Confluence page](https://memobank.atlassian.net/wiki/spaces/RD/pages/4066662/Feature+flags+present+across+our+web+apps)
 * in sync when updating this list.
 */
export const AVAILABLE_FEATURE = {
  ...COMMON_AVAILABLE_FEATURE,
  ACCOUNTING: 'accounting',
  BOOSTER_ACCOUNT: 'booster-account',
  CONTEXTUAL_PANEL: 'contextual-panel',
  // 📜 Read details here: https://memobank.slack.com/archives/C05QFFS6458/p1702320986924739
  DISABLE_COLLECTION_EXPORT_QUICK_ACTION: 'disable-collection-export-quick-action',
  HTTP_PATCH: 'http-patch',
  ONBOARDING_REDESIGN: 'onboarding-redesign',
  SIDE_PANEL_PAGINATION: 'side-panel-pagination',
  SWEEP_TRANSFER_RULES: 'sweep-transfer-rules',
  SWIFT_PRICING_PROMOTION: 'swift-pricing-promotion',
  TESTING_TOOLS: 'testing-tools',
} as const;

export const AVAILABLE_LOCALES = ['en', 'fr'] as const;

export const DEFAULT_CURRENCY_SYMBOL = '€';

export const DEFAULT_LOCALE = 'fr';
